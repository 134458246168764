import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import "./fonts/CraftworkGrotesk-Heavy.ttf"

import MainPage from './pages/MainPage';
import ReactionFiltersPage from './pages/ReactionFiltersPage';
import SubSearchPage from './pages/SubSearchPage';
import ExactSearchPage from './pages/ExactSearchPage';
import ReactionEditorPage from './pages/ReactionEditor'; 
import MoleculeEditorPage from './pages/MoleculeEditorPage';
import theme from "./theme";

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/main" >
            <MainPage />
          </Route>

          <Route path="/reaction-editor" >
            <ReactionEditorPage />
          </Route>

          <Route path="/exact-search" >
          {(props: any) =><ExactSearchPage {...props} />}
          </Route>

          <Route path="/reaction-filters" >
          {(props: any) =><ReactionFiltersPage {...props} />}
          </Route>

          <Route path="/sub-search" >
          {(props: any) =><SubSearchPage {...props} />}
          </Route>

          <Route path="/editor/:session" >
            {(props: any) => <MoleculeEditorPage {...props} />}
          </Route>

          <Route exact path="/"
            render={() => { return (<Redirect to="/main" />) }}
          />
          
        </Switch>
      </ThemeProvider>

    </Router>
  );
}

export default App;
