import AppSettings from '../API/appSettings';


function getReaction() {
  let reaction = localStorage.getItem(AppSettings.LOCAL_REACT_MOL_KEY) || '';
  return reaction
}


function getReactantMolecule() {
  let reaction = localStorage.getItem(AppSettings.LOCAL_MOL_ + AppSettings.REACTANT) || '';
  return reaction
}


function getReactantSVG(reactantId: number) {
  let reaction = localStorage.getItem(AppSettings.LOCAL_SVG_ + reactantId.toString()) || '';
  return reaction
}


function cleanReactant(reactantId: number) {
  localStorage.removeItem(AppSettings.LOCAL_SVG_ + reactantId.toString());
  localStorage.removeItem(AppSettings.LOCAL_MOL_ + reactantId.toString());
  while (localStorage.getItem(AppSettings.LOCAL_SVG_ + (reactantId + 1).toString())!==null) {
    localStorage.setItem(AppSettings.LOCAL_SVG_ + (reactantId).toString(), localStorage.getItem(AppSettings.LOCAL_SVG_ + (reactantId + 1).toString())!);
    localStorage.setItem(AppSettings.LOCAL_MOL_ + (reactantId).toString(), localStorage.getItem(AppSettings.LOCAL_MOL_ + (reactantId + 1).toString())!);
    localStorage.removeItem(AppSettings.LOCAL_SVG_ + (reactantId + 1).toString());
    localStorage.removeItem(AppSettings.LOCAL_MOL_ + (reactantId + 1).toString());
    reactantId++;
  }
}

export { getReaction, getReactantMolecule, getReactantSVG, cleanReactant };