
function apiVersion(): string { return '/api/site'; }

function getHeaders(authorization: boolean, contentType: string | undefined = 'application/json;charset=UTF-8') {
  let headers: any = {};
  if (contentType !== 'application/x-zip-compressed')
    headers['content-type'] = contentType;
  return headers;
}


async function fetchPost(endPoint: string,
  body: string | FormData,
  useVersion: boolean = true,
  authorization: boolean = false,
  contentType: string | undefined = 'application/json;charset=UTF-8'
): Promise<any> {
  let attempts = authorization ? 2 : 1;
  while (attempts > 0) {
    attempts--;

    const response = await fetch(buildUrl(useVersion, endPoint), {
      method: 'POST',
      headers: getHeaders(authorization, contentType),
      body: body,
    });
    if (response?.ok) return response;
    else
      if (attempts === 0) return response;
  }
}


function buildUrl(useVersion: boolean, endPoint: string) {
  return (useVersion ? apiVersion() : '/api') + endPoint
}


async function fetchGet(endPoint: string, useVersion: boolean = true, authorization: boolean = false): Promise<any> {
  let attempts = authorization ? 2 : 1;
  while (attempts > 0) {
    attempts--;
    const response = await fetch(buildUrl(useVersion, endPoint), {
      method: 'GET',
      headers: getHeaders(authorization),
    });
    if (response.ok) return response;
    else
      if (authorization && response.status === 403) {
      }
    if (attempts === 0) return response;
  }
}


async function fetchPut(endPoint: string, body: string | FormData, useVersion: boolean = true,
  authorization = false): Promise<any> {
  let attempts = authorization ? 2 : 1;
  while (attempts > 0) {
    attempts--;
    const response = await fetch(buildUrl(useVersion, endPoint),
      {
        method: 'PUT',
        headers: getHeaders(authorization),
        body: body,
      });
    if (response.ok) return response;
  }
}


async function fetchDelete(endPoint: string, useVersion: boolean = true,
  authorization = false): Promise<any> {
  const response = await fetch(buildUrl(useVersion, endPoint),
    {
      method: 'DELETE',
      headers: getHeaders(authorization),
    });
  return response;
}


async function reportError(body: string): Promise<any> {
  const reportEndPoint = '/misc/report-error';
  return await fetchPost(reportEndPoint, body);
}
export { fetchGet, fetchPost, fetchPut, fetchDelete, reportError };