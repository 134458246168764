import IReactionRequestParams from './IReactionRequestParams';
import IExtraFilterModel from './IExtraFilterModel';
import { cleanReactant } from '../services/Session';

export default class ReactionRequestParams {
    
    public static addReactionParam(params: IExtraFilterModel[] ): IExtraFilterModel[] {
        if (!params)
            params = [];
        
        cleanReactant(params.length);
    
        const param = {
            typeOfFilter: 'exact',
            mol: '',
            role: 'reagent',
        } as IExtraFilterModel;
        params.push(param);
        return params;
    }


    public static createReactionParam(): IReactionRequestParams {
        return {
            extraFilterModel:[]
        };
    }


    public static removeParam(params: IExtraFilterModel[], excludeIndex: number): IExtraFilterModel[] {
        cleanReactant(excludeIndex);
        params = params.filter((param: IExtraFilterModel, index) => index !== excludeIndex);
        return params;
    }
}
