import * as React from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { fetchGet } from '../services/GettingData';
import ISolvent from '../schemas/ISolvent';

type ISolventProps = { anyPossible: boolean, 
                       onChange: (e: any) => void;
                       selected?: string };


export const SolventControl: React.FC<ISolventProps> = (props) => {
    
 
    const [solvents, setSolvents] = React.useState<ISolvent[]>([]);
    
    const getSolvents = async () => {
        const response = await fetchGet('/misc/solvents');
        const sol = await response.json();
        sol.sort((a: ISolvent, b: ISolvent) => (a.name < b.name ? -1 : 1));
        if (props.anyPossible)
            sol.unshift({name:'any', solvent_id:'any'});
        setSolvents(sol);
    }


    React.useEffect(()=>{
        getSolvents();
    }, []);


    let listItem = Object.values(solvents).map((value: ISolvent) => 
        (<MenuItem style={{fontFamily: 'Roboto, Helvetica'}} 
                   key={value.solvent_id} value={value.solvent_id}>{value.name}</MenuItem>)
    );

    
    return (<Box sx={{ minWidth: 120 }}>
        {(solvents.length > 0) &&
        <FormControl fullWidth >
            <InputLabel id="solvent-selector-label-header"></InputLabel>
                    <Select
                        labelId="solvent-selector-label"
                        id="solvent-selector-id"
                        style={{fontFamily: 'Roboto, Helvetica'}}
                        label=""
                        onChange={props.onChange}
                        value={props.selected ? props.selected : props.anyPossible ? 'any' : 'any'}>
                        {listItem}
                    </Select>
        </FormControl>}
    </Box>
    );
};

