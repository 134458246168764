export default class AppSettings
{
    static readonly LOCAL_REACT_MOL_KEY = 'drawn_react_str';
    static readonly LOCAL_REACT_SVG_KEY = 'drawn_react_svg';

    static readonly LOCAL_MOL_ = 'drawn_mol_';
    static readonly LOCAL_SVG_ = 'drawn_svg_';

    static readonly REACTANT = 'reactant';
    static readonly PRODUCT = 'product';
};