import { Typography } from '@mui/material';
import React from 'react';

import AppSettings from '../API/appSettings';

type IProps = { //session: string,
  //spectrumPreview?: ISpectrumPreviewModel
};


const Reactangle: React.FC<IProps> = (props) => {

const emptyContainer = () => {
  return (<span></span>)
}

const svgString = encodeURIComponent((localStorage.getItem(AppSettings.LOCAL_REACT_SVG_KEY) as string));
const dataUri = `url("data:image/svg+xml,${svgString}")`;
const phrase = 'Click below to open Ketcher';

const MW = (<div className={'mol-container'} 
style={{ height: '344px', width:'auto', alignItems: 'center', background: dataUri }}>
{emptyContainer()}
</div>);


React.useEffect(() => {
  let moleculeContainers: any = Array.from(document.getElementsByClassName('mol-container'));
  moleculeContainers.map((moleculeContainer: any) => {
      moleculeContainer.style.backgroundSize = 'contain';
      moleculeContainer.style.backgroundRepeat = 'no-repeat';
      moleculeContainer.style.backgroundPositionY = 'center';
      moleculeContainer.style.margin = '20px';
  });

  let moleculeInlines: any = Array.from(document.getElementsByClassName('mol-inline'));
  moleculeInlines.map((moleculeInline: any) => {
      moleculeInline.style.backgroundSize = 'contain';
      moleculeInline.style.backgroundRepeat = 'no-repeat';
      moleculeInline.style.backgroundPositionY = 'center';
      moleculeInline.style.margin = '0em';
  });

}, []);



    return (
      <a href='/reaction-editor'>
        <div className='logo-block' style={{
          border: '3px solid #4a4e69',
          borderRadius: '10px',
          width: '1045px',
          height: '387px',
          position: 'absolute',
          top: '237px',
          left: '198px'
        }}>
          <div style={{ textAlign: "center" }}>
            <Typography color="primary.main" style={{ fontSize: '40px' }}>{}</Typography>
            {MW}

          
 
          </div>
        </div>
      </a>
    );
  }
  export default Reactangle;           