import React from 'react';

function Doi(id?: string, title?: string, volumeInfo: Record<string, any> = {}) {
  let pages = '';
  if (volumeInfo){
    if (volumeInfo.volume)
      pages = ', ' + volumeInfo.volume;
    if (volumeInfo.pages)
      pages = pages + ', ' + volumeInfo.pages;
 
  }
  return (<a href={'https://doi.org/' + id}  target="_blank">{title ? title + pages : 'https://doi.org/' + id}</a>);
}

function DoiShort(id?: string, title?: string) {
  return (<a href={'https://doi.org/' + id}  target="_blank">{title ? title : id}</a>);
}

const gotoDoi = (doi: string) => window.open('https://doi.org/' + doi);
export default { Doi, DoiShort, gotoDoi };
  