import React from 'react';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';

import Logo from '../components/Logo';
import Reactangle from '../components/Reactangle';
import {getReaction} from '../services/Session';

import '../App.css';
//import Header from '../components/HeaderComponent';


const MainPage: React.FC<{}> = () => {

  const [isReactionIn, setReactionIn] = React.useState<boolean>(false);

  const validateReaction = () => {
    setReactionIn(getReaction() ? true : false);
  }


  React.useEffect(() => {
    validateReaction();
  }, []);


  return (
    <Grid container md={12} spacing={0} className='main-frame' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Grid style={{ width: "1440px", position: 'fixed' }}>
        <div className='aa' style={{ top: '56px', left: '42px' }}>
          <Logo />
          <Reactangle />
          <Grid container xs={12} className='ase' style={{ top: '649px', textAlign: "center", width: "1440px", position: 'relative' }}>
            <Grid xs={6}>
             {isReactionIn ?  
              <Link to='sub-search'>
                <Button disabled={!isReactionIn}>SUBSTRUCTURE SEARCH</Button>
              </Link> :
                <Button disabled>SUBSTRUCTURE SEARCH</Button>
              }
            </Grid>
            <Grid xs={6}>
              
            { 
              isReactionIn ?  
              <Link to='exact-search'>
                <Button>EXACT SEARCH</Button>
              </Link> :
              <Button disabled >EXACT SEARCH</Button>
            }

            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default MainPage;
