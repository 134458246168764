
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ISearchModel from '../schemas/ISearchModel';
import IReactionRequestParams from '../schemas/IReactionRequestParams';

interface IProps {
    svg?: string,
    isMoleculeInContainer: boolean,
    moleculeId?: string,
    link: string,
    inline?: boolean,
    backToPage?: string,
    searchModel?: ISearchModel,
    tooltip?: string,
    moleculeString?: string,
    requestParams?: IReactionRequestParams
}

const MoleculeView: React.FC<IProps> = (props) => {
    const svgString = encodeURIComponent(props.svg ? props.svg : '');
    const dataUri = `url("data:image/svg+xml,${svgString}")`;
    const phrase = 'Click below to open Ketcher';

    useEffect(() => {
        let moleculeContainers: any = Array.from(document.getElementsByClassName('mol-container'));
        moleculeContainers.map((moleculeContainer: any) => {
            moleculeContainer.style.backgroundSize = 'contain';
            moleculeContainer.style.backgroundRepeat = 'no-repeat';
            moleculeContainer.style.backgroundPositionY = 'center';
            moleculeContainer.style.margin = '10px';
        });

        let moleculeInlines: any = Array.from(document.getElementsByClassName('mol-inline'));
        moleculeInlines.map((moleculeInline: any) => {
            moleculeInline.style.backgroundSize = 'contain';
            moleculeInline.style.backgroundRepeat = 'no-repeat';
            moleculeInline.style.backgroundPositionY = 'center';
            moleculeInline.style.margin = '0em';
        });

    }, [props.svg]);


    const emptyContainer = () => {
        if (!props.isMoleculeInContainer) return (<span>Draw Structure Here</span>)
    }

  const phraseAbove = (<div style={{
    marginTop: '20px',
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: '30px',
    marginRight: '30px',
    textAlign: 'center',
    borderRadius: '3px',
    padding: '3px',
    color: 'rgb(74, 78, 105)',
    opacity: '80%'
  }}>{phrase}</div>);


    const MW = (<div className={props.hasOwnProperty('inline') ? 'mol-inline' : 'mol-container'} 
                     style={{ height: '200px', 
                     width: '200px',
                     marginLeft: '5px', 
                     alignItems: 'center', background: dataUri }}>
        {emptyContainer()}
    </div>);

    if (props.moleculeId || props.link)
    return (
      <div className='molecule-view-control'>
        {phraseAbove}
        <Link to={{
          pathname: props.link,
          state: {
            searchModel: props.searchModel,
            requestParams: props.requestParams,
            backToPage: props.backToPage
          },
        }} style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title={props.tooltip ? props.tooltip : 'Click here to draw molecule'}>
            {MW}
          </Tooltip>
        </Link>

        {props.moleculeString &&
          <Tooltip title={'Copy molecule'}>
            <ContentCopyIcon onClick={() => { navigator.clipboard.writeText(props.moleculeString as string) }}
              style={{
                verticalAlign: 'right', marginRight: '0.3em', float: 'right', cursor: 'pointer'
              }} color="primary" />
          </Tooltip>
        }
      </div>

    );
  return MW;
};

export default MoleculeView;