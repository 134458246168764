import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Item } from '../styles';

function ProgressControl() {
  return (<Item style={{
    padding: '3em',
    top: '50%',
    left: '50%',
    marginTop: '-10em',
    marginLeft: '-7em',
    position: 'fixed',
    color: 'rgb(255, 121, 45)',
    zIndex: '999',
  }}><CircularProgress style={{ color: 'rgb(255, 121, 45)' }} size={100} /></Item>
  );
}
export default ProgressControl;


