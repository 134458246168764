import React from 'react';
import Button from '@mui/material/Button';
import { useHistory, useLocation } from "react-router-dom";
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { SelectChangeEvent } from '@mui/material/Select';

import '../App.css';
import Header from '../components/Header';
import ErrorDialog from '../components/ErrorDialog';
import { getReactantSVG } from '../services/Session';
import MoleculeView from '../components/MoleculeView';
import { SolventControl } from '../components/SolventControl';
import ReactionRequestParams from '../schemas/ReactionRequestParams';
import IReactionRequestParams from '../schemas/IReactionRequestParams';
import IExtraFilterModel from '../schemas/IExtraFilterModel';

const ALPHA_NUMERIC_DASH_REGEX = /^[,-.-Z0-9-]+$/;

type IFilterProps = {
  requestParams: IReactionRequestParams,
  rxn?: string,
  svg?: string,
  backToPage: string
};

const ReactionFiltersPage: React.FC<IFilterProps> = (props) => {

  let history = useHistory();
  let location = useLocation();

  //const [isLoading, setLoading] = React.useState<boolean>(false); TODO for mol search
  const [requestParams, setRequestParams] = React.useState<IReactionRequestParams>((props as any).location.state.requestParams);
  const [isDialogOpened, setDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
 

  const validateDigitInput = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key != "Backspace" && !ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
      event.preventDefault();
    }
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setDialogOpened(true);
  }


  const closeErrorDialog = () => {
    setDialogOpened(false);
  }


  const submit = () => {
    const initialProps = location.state as IFilterProps

    const mess = validate();
    if (mess)
      showError(mess)
    else
      if (initialProps.backToPage){
        history.push({
          pathname: initialProps.backToPage,
          state: { requestParams: requestParams, rxn: initialProps.rxn, svg: initialProps.svg }
        })
      }
      else
        history.push({ pathname: '/exact-search', state: { requestParams: requestParams } })
  }


  const changeSolventHandler = (e: SelectChangeEvent) => {
    setRequestParams({...requestParams, solvents: e.target.value === 'any' ? undefined : [e.target.value] });
  }


  const ColumnHeader = (title: string) => (
    <div style={{
      margin: "auto", textAlign: 'center',
      border: '3px solid #4a4e69',
      borderRadius: '10px'
    }}>
      <Typography style={{ marginTop: '7px', marginBottom: '7px' }} color="primary" fontSize={'24px'}>{title}</Typography>
    </div>);


  const validate = () => {
    if (requestParams.min_yield && requestParams.min_yield > 100) return 'expected Yield less 100%';
    if (requestParams.max_yield && requestParams.max_yield > 100) return 'expected Yield less 100%';
    if (requestParams.max_yield && requestParams.min_yield && requestParams.min_yield > requestParams.max_yield) return 'expected min Yield less max Yield';
    if (requestParams.max_temp_c && requestParams.max_temp_c > 1000) return 'expected temperature less 1000';
  }


  return (
    <Grid container md={12} spacing={0} className='main-frame' style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', color: '#a4e69' }}>
      <ErrorDialog isDialogOpened={isDialogOpened}
        errorMessage={errorMessage}
        warningMessage={''}
        onClose={closeErrorDialog} />

      <Grid style={{ width: "1440px" }}>
        <Grid>
          <Header />
        </Grid>
        <Grid xs={12}>
          <div style={{ marginLeft: '188px' }}>
            <Typography color="primary" fontSize={'96px'}>REACTION</Typography>
          </div>
          <div style={{ marginLeft: '544px', marginTop: '-50px' }}>
            <Typography color="primary" fontSize={'96px'}><span style={{ color: '#ff792d' }}>FILTERS</span></Typography>
          </div>
        </Grid>

        <Grid xs={12} style={{ marginTop: '100px', textAlign: 'right', border: '3px solid #4a4e69', borderRadius: '10px' }}>
          <Typography style={{ float: 'left', paddingLeft: '22px', marginTop: '9px' }} fontSize={'24px'}>Parameters filter</Typography>
          <Button style={{
            WebkitBackdropFilter: 'blur(309px) brightness()',
            backdropFilter: 'blur(309px) brightness()',
            backgroundColor: '#ffffff5e',
            color: '#585c75',
            borderRadius: '5px',
            width: '182px',
            height: '59px',
            position: 'relative',
            boxShadow: 'inset 0 1px 40px #91919133, inset 0 4px 18px #6c6c6c4c, inset 0 98px 100px -48px #8a8a8a4c, 0 4px 210px #ffffff9e'

          }} variant="contained" onClick={submit}>APPLY FILTERS</Button>
        </Grid>

        <Grid container style={{
          borderBottom: '3px solid #4a4e69',
          borderLeft: '3px solid #4a4e69',
          borderRight: '3px solid #4a4e69',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          marginTop: '-6px',
          paddingLeft: '22px'
        }}>

          <Grid container xs={12} style={{ marginTop: '25px', width: '100%' }}>
            <Grid style={{ marginTop: 'auto', marginBottom: 'auto', width: '250px' }}>
              <Typography style={{ fontFamily: 'Roboto, Helvetica', fontWeight: '500px' }} fontSize={'20px'}>Minimum yield:
              </Typography>
            </Grid>
            <Grid>
              <FormControl sx={{ m: 1, width: '45ch' }} variant="outlined">
                <OutlinedInput
                  id="minimum-yield"
                  defaultValue={requestParams?.min_yield}
                  style={{ fontFamily: 'Roboto, Helvetica' }}
                  onKeyDown={(event) => validateDigitInput(event)}
                  onChange={(event) => setRequestParams({ ...requestParams, min_yield: event.target.value ? Number(event.target.value) : undefined })}
                  endAdornment={<InputAdornment className="min-time-end-adornment" position="end">%</InputAdornment>}
                  aria-describedby="minimum-yield-helper-text"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container xs={12} style={{ marginTop: '20px', width: '100%' }}>
            <Grid style={{ width: '250px', marginBottom: 'auto', marginTop: 'auto' }}>
              <Typography style={{ fontFamily: 'Roboto, Helvetica', fontWeight: '500px' }} fontSize={'20px'}>Maximum yield:</Typography>
            </Grid>
            <Grid style={{ width: '250px' }}>
              <FormControl sx={{ m: 1, width: '45ch' }} variant="outlined">
                <OutlinedInput
                  id="maximal-yield"
                  style={{ fontFamily: 'Roboto, Helvetica' }}
                  onKeyDown={(event) => validateDigitInput(event)}
                  defaultValue={requestParams?.max_yield}
                  onChange={(event) => setRequestParams({ ...requestParams, max_yield: event.target.value ? Number(event.target.value) : undefined })}
                  endAdornment={<InputAdornment className="max-yield-end-adornment" position="end">%</InputAdornment>}
                  aria-describedby="max-yield-helper-text"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container xs={12} style={{ marginTop: '20px', width: '100%' }}>
            <Grid style={{ marginTop: 'auto', marginBottom: 'auto', width: '250px' }}>
              <Typography style={{ fontFamily: 'Roboto, Helvetica', fontWeight: '500px' }} fontSize={'20px'}>Solvent: </Typography>
            </Grid>
            <Grid>
              <FormControl sx={{ m: 1, width: '45ch' }} variant="outlined">
                <SolventControl anyPossible={true}
                  onChange={changeSolventHandler}
                  selected={requestParams?.solvents ? requestParams.solvents[0] : 'any'} />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container xs={12} style={{ marginTop: '20px', width: '100%' }}>
            <Grid style={{ width: '250px', marginTop: 'auto', marginBottom: 'auto', }}>
              <Typography style={{ fontFamily: 'Roboto, Helvetica', fontWeight: '500px' }} fontSize={'20px'}>Maximum temperature:</Typography>
            </Grid>
            <Grid>
              <FormControl sx={{ m: 1, width: '45ch' }} variant="outlined">
                <OutlinedInput
                  id="min-temp-c"
                  style={{ fontFamily: 'Roboto, Helvetica' }}
                  defaultValue={requestParams?.max_temp_c}
                  onKeyDown={(event) => validateDigitInput(event)}
                  onChange={(event) => setRequestParams({ ...requestParams, max_temp_c: event.target.value ? Number(event.target.value) : undefined })}
                  endAdornment={<InputAdornment className="max-temp-c-adornment" position="end">°C</InputAdornment>}
                  aria-describedby="max-temp-c-helper-text"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container xs={12} style={{ marginTop: '20px', width: '100%' }}>
            <Grid style={{ marginTop: 'auto', marginBottom: 'auto', width: '250px' }}>
              <Typography style={{ fontFamily: 'Roboto, Helvetica', fontWeight: '500px' }} fontSize={'20px'}>Minimal scale:</Typography>
            </Grid>
            <Grid>
              <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined" style={{ fontFamily: 'Roboto, Helvetica' }}>
                <OutlinedInput
                  id="max-temp-c"
                  style={{ fontFamily: 'Roboto, Helvetica' }}
                  onKeyDown={(event) => validateDigitInput(event)}
                  defaultValue={requestParams?.scale?.scale_mmol}
                  onChange={(event) => {
                    setRequestParams({
                      ...requestParams, scale: event.target.value ? {
                        op: 'ge',
                        scale_mmol: Number(event.target.value)
                      } : undefined
                    })
                  }}
                  endAdornment={<InputAdornment className="min-time-end-adornment" position="end" style={{ fontFamily: 'Roboto, Helvetica' }}>mMol</InputAdornment>}
                  aria-describedby="scale-helper-text"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container xs={12} style={{ marginTop: '20px', marginBottom: '25px', width: '100%' }}>
            <Grid style={{ width: '250px', marginTop: 'auto', marginBottom: 'auto' }}>
              <Typography style={{ fontFamily: 'Roboto, Helvetica', fontWeight: '500px' }} fontSize={'20px'}>Maximum time</Typography>
            </Grid>
            <Grid>
              <FormControl sx={{ m: 1, width: '45ch' }} variant="outlined">
                <OutlinedInput
                  id="min-time"
                  style={{ fontFamily: 'Roboto, Helvetica' }}
                  onKeyDown={(event) => validateDigitInput(event)}
                  onChange={(event) =>
                    setRequestParams({ ...requestParams, max_time_h: event.target.value ? Number(event.target.value) : undefined })}
                  endAdornment={<InputAdornment className="min-time-end-adornment" position="end">h.</InputAdornment>}
                  defaultValue={requestParams?.max_time_h}
                  aria-describedby="min-time-helper-text"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        { (props as any).location.state.backToPage === '/sub-search' &&
        <Grid container style={{ textAlign: 'left', border: '3px solid #4a4e69', borderRadius: '10px', padding: '8px', marginTop: '20px', display: 'inherit' }}>
          <Grid xs={8} style={{ marginRight: '-3px' }}>
            <Typography style={{ paddingLeft: '22px', marginTop: 'auto', marginBottom: 'auto', color: '#4a4e69' }} fontSize={'24px'}>Starting molecules filter</Typography>
          </Grid>
          <Grid xs={3} style={{textAlign: 'right'}}>
            <Button variant="contained"
              style={{ color: '#585c75' }}
              onClick={() => {
                setRequestParams({ ...requestParams, 
                                   extraFilterModel: ReactionRequestParams.addReactionParam(requestParams.extraFilterModel) })}
              }>ADD FILTER</Button>

          </Grid>
        </Grid>
        }

        {requestParams.extraFilterModel && (props as any).location.state.backToPage === '/sub-search' &&
        <Grid xs={12} container style={{ marginTop: '-3px' }}>
          <Grid xs={4} style={{ width: '482px', marginRight: '-3px' }}>
            {ColumnHeader('Molecule')}
          </Grid>
          <Grid xs={4} style={{ width: '482px', marginRight: '-3px' }}>
            {ColumnHeader('Type of filter')}
          </Grid>
          <Grid xs={4} style={{ width: '482px' }}>
            {ColumnHeader('Role')}
          </Grid>
        </Grid>}

        {requestParams.extraFilterModel && (props as any).location.state.backToPage === '/sub-search' && 
         requestParams.extraFilterModel.map((reaction: IExtraFilterModel, index: number) => (
        <Grid xs={12} container style={{ marginTop: '-6px' }} key={index}>
          <Grid xs={4} style={{
            width: '482px',
            marginRight: '-3px',
            borderBottom: '3px solid #4a4e69',
            borderLeft: '3px solid #4a4e69',
            borderRight: '3px solid #4a4e69',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
          }}>
            <MoleculeView svg={ getReactantSVG(index)} 
                          isMoleculeInContainer={true} 
                          link={'/editor/' + (index).toString()} 
                          backToPage={'/sub-search'}
                          requestParams={requestParams} />
          </Grid>
          <Grid xs={4} style={{
            width: '482px',
            marginRight: '-3px',
            borderBottom: '3px solid #4a4e69',
            borderLeft: '3px solid #4a4e69',
            borderRight: '3px solid #4a4e69',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            padding: '25px',
            minHeight: '200px',
          }}>
            <FormControl fullWidth style={{ marginTop: '80px' }}>
              <InputLabel id="reagent-type-selector-label-header"></InputLabel>
              <Select
                labelId="reagent-type-label"
                id="reagent-type-id"
                style={{ fontFamily: 'Roboto, Helvetica' }}
                label=""
                value={reaction.typeOfFilter}
                onChange={(value)=>{
                    setRequestParams({
                      ...requestParams, extraFilterModel: requestParams.extraFilterModel.map((e, i) => {
                        if (i === index) {
                          e.typeOfFilter = value.target.value;
                          return e;
                        }
                        return e;
                      })
                    })
                }}
              >
                <MenuItem style={{ fontFamily: 'Roboto, Helvetica' }}
                  key={'exact'} value={'exact'}>{'exact'}</MenuItem>
                <MenuItem style={{ fontFamily: 'Roboto, Helvetica' }}
                  key={'substructure'} value={'substructure'}>{'substructure'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={4} style={{
            width: '482px',
            marginRight: '-3px',
            borderBottom: '3px solid #4a4e69',
            borderLeft: '3px solid #4a4e69',
            borderRight: '3px solid #4a4e69',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            padding: '25px',
            minHeight: '200px',
          }}>

            <FormControl fullWidth style={{ marginTop: '80px' }}>
              <InputLabel id="reagent-type-selector-label-header"></InputLabel>
              <Select
                labelId="reagent-type-label"
                id="reagent-type-id"
                style={{ fontFamily: 'Roboto, Helvetica' }}
                label=""
                value={reaction.role}
                  onChange={(value) => {
                    setRequestParams({
                      ...requestParams, extraFilterModel: requestParams.extraFilterModel.map((e, i) => {
                        if (i === index) {
                          e.role = value.target.value;
                          return e;
                        }
                        return e;
                      })
                    })
                  }}
                >
                <MenuItem style={{ fontFamily: 'Roboto, Helvetica' }}
                  key={'reactant'} value={'reactant'}>{'reactant'}</MenuItem>
                <MenuItem style={{ fontFamily: 'Roboto, Helvetica' }}
                  key={'reagent'} value={'reagent'}>{'reagent'}</MenuItem>
                <MenuItem style={{ fontFamily: 'Roboto, Helvetica' }}
                  key={'catalyst'} value={'catalyst'}>{'catalyst'}</MenuItem>
                <MenuItem style={{ fontFamily: 'Roboto, Helvetica' }}
                  key={'reactant+reagent'} value={'reactant+reagent'}>{'reactant+reagent'}</MenuItem>
                <MenuItem style={{ fontFamily: 'Roboto, Helvetica' }}
                  key={'reactant+catalyst'} value={'reactant+catalyst'}>{'reactant+catalyst'}</MenuItem>
                <MenuItem style={{ fontFamily: 'Roboto, Helvetica' }}
                  key={'reagent+catalyst'} value={'reagent+catalyst'}>{'reagent+catalyst'}</MenuItem>
                <MenuItem style={{ fontFamily: 'Roboto, Helvetica' }}
                  key={'any'} value={'any'}>{'any'}</MenuItem>
              </Select>
            </FormControl>

            <Button variant="contained"
              style={{ color: '#585c75', marginTop:'1em', float:'right' }}
              onClick={() => {
                setRequestParams({ ...requestParams, extraFilterModel: ReactionRequestParams.removeParam(requestParams.extraFilterModel, index)})}
              }>REMOVE</Button>


          </Grid>
        </Grid>
        ))}


        <Grid container style={{ marginBottom: '0px' }}>
          <Grid container md={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ minHeight: '50px' }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  );
}

export default ReactionFiltersPage;