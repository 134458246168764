import React, { lazy, Suspense } from 'react';
import ProgressControl from '../components/ProgressControl';

const LazyMoleculeEditorPage = lazy(() => import('./LazyMoleculeEditorPage'));

function MoleculeEditorPage(props: any) {
  return (
    <div>
      <Suspense fallback={<div><ProgressControl/></div>}>
        <LazyMoleculeEditorPage session={props.match.params['session']} 
                                backToPage={props.location.state['backToPage']}
                                requestParams={
                                  (props.location.state && 'requestParams' in props.location.state) ? props.location.state['requestParams'] : null
                                  } />
      </Suspense>
    </div>);
}

export default MoleculeEditorPage;
