import React from 'react';
import { useHistory, useLocation } from "react-router-dom";

import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import '../App.css';
import Doi from '../components/Doi';
import Header from '../components/Header';
import ICompound from '../schemas/ICompound';
import { getReaction } from '../services/Session';
import ISolvent from '../schemas/ISolventReaction';
import ISearchModel from '../schemas/ISearchModel';
import { fetchPost } from '../services/GettingData';
import ReactionView from '../components/ReactionView';
import MoleculeView from '../components/MoleculeView';
import IReactionModel from '../schemas/IReactionModel';
import ProgressControl from '../components/ProgressControl';
import IReactionPartModel from '../schemas/IReactionPartModel'; 
import ReactionRequestParams from '../schemas/ReactionRequestParams';
import IReactionRequestParams from '../schemas/IReactionRequestParams';
import IReactionSearchResponse from '../schemas/IReactionSearchResponse';


//type IExactSearchProps = { searchModel?: ISearchModel };
type IExactSearchProps = {
  requestParams: IReactionRequestParams,
  rxn?: string,
  svg?: string,
};

const ExactSearchPage: React.FC<IExactSearchProps> = (props) => {

  let history = useHistory();
  let location = useLocation();

  const findRxn = (): string => {
    const key = 'exact-search-rxn';
    let rxn: string | undefined = undefined;
    if ((props as any).location?.state?.rxn)
      rxn = (props as any).location.state.rxn;
    if (!rxn && localStorage.getItem(key)){
      rxn = localStorage.getItem(key) as string;
      localStorage.removeItem(key);
    }
    if (!rxn)
      rxn = getReaction()
    return rxn;
  }

  
  const findParams = (): IReactionRequestParams => {
    const exactProps = location.state as IExactSearchProps;
    const key = 'exact-search-params';
    let params: IReactionRequestParams | undefined = undefined;
    if (exactProps?.requestParams)
      params = exactProps.requestParams;
    if (!params && localStorage.getItem(key)) {
      params = JSON.parse(localStorage.getItem(key)!);
      localStorage.removeItem(key);
    }
    return params ? params : ReactionRequestParams.createReactionParam();
  }


  const findSvg = (): string | undefined => {
    const key = 'exact-search-svg';
    const exactProps = location.state as IExactSearchProps;
    
    if (exactProps?.svg)
      return exactProps.svg;
    if (localStorage.getItem(key)){
        const svg: string = localStorage.getItem(key) as string;
        localStorage.removeItem(key);
        return svg;
      }
    else return undefined;
  }


  const [page, setPage] = React.useState<number>(1);

  const [searchModel, setSearchModel] = React.useState<ISearchModel>({ reaction_params: findParams(), rxn: findRxn()});
  const [svg] = React.useState<string | undefined>(findSvg());

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [searchReactionResponse, setSearchReactionResponse] = React.useState<IReactionSearchResponse>({ items: [], total: 0, page: 1, size: 10 });


  React.useEffect(() => {
    get();
  }, [page]);


  const round = (value: number) => {
    return Math.round(value * 100) / 100;
  }


  const RR = (reaction: IReactionModel, index: number) => {
    return (<div key={index}><Grid container style={{ textAlign: 'center', fontSize: '16px' }} key={reaction.id}>
      <Grid style={{
        fontFamily: 'Roboto, Helvetica', width: '220px', marginLeft: '170px',
        borderLeft: '3px solid #4a4e69',
        borderRight: '3px solid #4a4e69',
        paddingLeft: '7px',
        paddingRight: '7px',
        minHeight: '100px'
      }}>
        <div>
          {Doi.DoiShort(reaction.urn)}
        </div>
      </Grid>
      <Grid className='col-reaction' style={{ width: '220px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
        {reaction.reactive_compounds && reaction.reactive_compounds.map((reagent: ICompound, index: number) => {
          return (<div key={index}><div style={{ marginLeft: '-3px' }}><MoleculeView svg={reagent.svg} link='' isMoleculeInContainer={true} /></div>
            {reagent.mass_mg && <Typography style={{ fontFamily: 'Roboto, Helvetica' }}>{round(reagent.mass_mg)} mg</Typography>}
            {reagent.volume_ml && <Typography style={{ fontFamily: 'Roboto, Helvetica' }}>{round(reagent.volume_ml)} ml</Typography>}
            {reagent.amount_mmol && <Typography style={{ fontFamily: 'Roboto, Helvetica' }}>{round(reagent.amount_mmol)} mmol</Typography>}
            <Typography style={{ fontFamily: 'Roboto, Helvetica' }}>{reagent.compound_role}</Typography>
          </div>
          )
        })}
      </Grid>

      <Grid className='col-reaction' style={{ width: '220px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
        {reaction.temperatures_c &&
          reaction.temperatures_c.map((temperature: number, index: number) => {
            return (<Typography key={index} style={{ fontFamily: 'Roboto, Helvetica' }}>Temperature: {temperature}°C</Typography>)
          })
        }
        {reaction.solvents &&
          reaction.solvents.map((solvent: ISolvent, index: number) => {
            return (<Typography key={index} style={{ fontFamily: 'Roboto, Helvetica' }}>Solvent: {solvent.solvent_representation}</Typography>)
          })
        }
        {reaction.time_h && reaction.time_h.length > 0 &&
          reaction.time_h.map((tm: number, index: number) => {
            return (<Typography key={index} style={{ fontFamily: 'Roboto, Helvetica' }}>Time: {round(tm)} h.</Typography>)
          })
        }
      </Grid>
      <Grid className='col-reaction' style={{
        fontFamily: 'Roboto, Helvetica', width: '220px',
        borderLeft: '3px solid #4a4e69',
        borderRight: '3px solid #4a4e69',
        marginLeft: '-3px',
        wordWrap: 'break-word',
        paddingLeft: '7px',
        paddingRight: '7px'
      }}>
        {reaction.protocol}
      </Grid>
      <Grid className='col-reaction' style={{ fontFamily: 'Roboto, Helvetica', width: '220px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
        {reaction.products &&
          reaction.products.map((product: ICompound, index: number) => {
            return (<div key={index}><div style={{ marginLeft: '-3px' }}>
              <MoleculeView svg={product.svg} link='' isMoleculeInContainer={true} />
            </div>
              <div>{product.yield_percent}% yield</div>
              <div>{round(product.amount_mmol)} mmol</div>
              <div>{round(product.mass_mg)} mg</div>
              {product.volume_ml && <div>{round(product.volume_ml)} ml</div>}
            </div>)
          })
        }
      </Grid>
    </Grid>
      {delimiter()}
    </div>);
  }


  const delimiter = () => {
    return (<Grid container style={{ minHeight: '50px' }}>
      <Grid style={{ width: '220px', marginLeft: '170px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69' }}>
      </Grid>
      <Grid className='col-reaction' style={{ width: '220px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
      </Grid>
      <Grid className='col-reaction' style={{ width: '220px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
      </Grid>
      <Grid className='col-reaction' style={{
        width: '220px',
        borderLeft: '3px solid #4a4e69',
        borderRight: '3px solid #4a4e69',
        marginLeft: '-3px',
        paddingLeft: '5px',
        paddingRight: '5px'
      }}>
      </Grid>
      <Grid className='col-reaction' style={{ width: '220px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
      </Grid>
    </Grid>);
  }


  const LL = () => {
    return (<Grid container style={{ textAlign: 'center', marginTop: '-7px', marginBottom: '-15px' }}>
      <Grid style={{ height: '20px', width: '220px', marginLeft: '170px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69' }}>
      </Grid>
      <Grid className='col-reaction' style={{ width: '220px', borderLeft: '3px solid #4a4e6xx9', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
      </Grid>
      <Grid className='col-reaction' style={{ width: '220px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
      </Grid>
      <Grid className='col-reaction' style={{ width: '220px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
      </Grid>
      <Grid className='col-reaction' style={{ width: '220px', borderLeft: '3px solid #4a4e69', borderRight: '3px solid #4a4e69', marginLeft: '-3px' }}>
      </Grid>
    </Grid>);
  }


  const ColumnHeader = (title: string) => (
    <div style={{
      margin: "auto", textAlign: 'center',
      border: '3px solid #4a4e69',
      borderRadius: '10px'
    }}>
      <Typography style={{ marginTop: '7px', marginBottom: '7px' }} color="primary" fontSize={'24px'}>{title}</Typography>
    </div>);


  const get = async () => {
    setLoading(true);
    const response = await fetchPost('/reaction/rexact?page=' + page + '&size=10', JSON.stringify(searchModel));
    if (response.ok) {
      const exactSearchResponse = await response.json();
      setSearchReactionResponse(exactSearchResponse);
    }
    setLoading(false);
  }


  React.useEffect(() => {
  }, [searchModel]);


  return (
    <Grid container md={12} spacing={0} className='main-frame' style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', color: '#4a4e69' }}>
      <Grid style={{ width: "1440px" }}>
        <Grid>
          <Header />
        </Grid>
        <Grid xs={12}>
          <div style={{ marginLeft: '188px' }}>
            <Typography color="primary" fontSize={'96px'}>
              <span>REACTION</span>
            </Typography>
          </div>

          <div style={{ marginLeft: '514px', marginTop: '-50px' }}>
            <Typography color="primary" fontSize={'96px'}>
              <span style={{ color: '#ff792d' }}>exact</span> search</Typography></div>
        </Grid>
        <Grid>
          <ReactionView svg={svg} />
        </Grid>

        <Grid xs={12} style={{ marginBottom: '100px', textAlign: 'center' }}>
          <Button variant="contained"
            style={{ color: '#585c75' }}
            onClick={() => {
              history.push({
                pathname: '/reaction-filters',
                state: {
                  requestParams : searchModel.reaction_params,
                  rxn: searchModel.rxn,
                  svg: svg,
                  backToPage: 'exact-search'
                }
              })
            }}>SET REACTION FILTERS</Button>
        </Grid>

        {isLoading ? <ProgressControl /> :
          <>
            <div style={{ paddingLeft: '200px', marginTop: '50px', marginBottom: '50px' }}>
              <Typography fontSize={'40px'} fontWeight={700}>
                Found {searchReactionResponse.total} {searchReactionResponse.total == 1 ? 'reaction:' : 'reactions:'}
              </Typography></div>
            {searchReactionResponse.items.length > 0 &&
              <Grid container style={{ marginBottom: '0px' }}>
                <Grid style={{ width: '220px', marginLeft: '170px' }}>
                  <div style={{
                    margin: "auto", textAlign: 'center',
                    border: '3px solid #4a4e69',
                    borderRadius: '10px'
                  }}>
                    <Typography style={{ marginTop: '7px', marginBottom: '7px' }} color="primary" fontSize={'24px'}>Reference</Typography>
                  </div>
                </Grid>
                <Grid style={{ width: '220px', marginLeft: '-3px' }}>
                  {ColumnHeader('Reagents')}
                </Grid>

                <Grid style={{ width: '220px', marginLeft: '-3px' }}>
                  {ColumnHeader('Conditions')}
                </Grid>
                <Grid style={{ width: '220px', marginLeft: '-3px' }}>
                  {ColumnHeader('Protocol')}
                </Grid>
                <Grid style={{ width: '220px', marginLeft: '-3px' }}>
                  {ColumnHeader('Products')}
                </Grid>
              </Grid>}
            <Grid>

              {searchReactionResponse.items.length > 0 && LL()}
              {searchReactionResponse.items.length > 0 && delimiter()}
              <Grid>
                {searchReactionResponse?.items &&
                  searchReactionResponse.items.map((reaction: IReactionModel, index: number) => (
                    (RR(reaction, index))))
                }
              </Grid>
            </Grid>

            {searchReactionResponse.items.length > 0 &&
              <Grid container style={{ marginBottom: '0px', minHeight: '20px' }}>
                <Grid style={{
                  width: '220px',
                  marginLeft: '170px',
                  borderBottom: '3px solid #4a4e69',
                  borderLeft: '3px solid #4a4e69',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  borderRight: '3px solid #4a4e69'
                }}>

                </Grid>
                <Grid style={{
                  width: '220px',
                  marginLeft: '-3px',
                  borderBottom: '3px solid #4a4e69',
                  borderLeft: '3px solid #4a4e69',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  borderRight: '3px solid #4a4e69'
                }}>
                </Grid>

                <Grid style={{
                  marginLeft: '-3px',
                  width: '220px',
                  borderBottom: '3px solid #4a4e69',
                  borderLeft: '3px solid #4a4e69',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  borderRight: '3px solid #4a4e69'
                }}>
                </Grid>
                <Grid style={{
                  marginLeft: '-3px',
                  width: '220px',
                  borderBottom: '3px solid #4a4e69',
                  borderLeft: '3px solid #4a4e69',

                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  borderRight: '3px solid #4a4e69',
                }}>
                </Grid>
                <Grid style={{
                  marginLeft: '-3px',
                  width: '220px',
                  borderBottom: '3px solid #4a4e69',
                  borderLeft: '3px solid #4a4e69',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  borderRight: '3px solid #4a4e69'
                }}>
                </Grid>
              </Grid>
            }
          </>}
        <Grid style={{ minHeight: '80px' }}>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default ExactSearchPage;