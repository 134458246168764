import * as React from 'react';
import logo from '../img/logo.png';
import { Typography } from '@mui/material';

function Header() {
  return (

    <div className='logo-block' style={{ height: '22px', border: '10px', position: 'inherit' }}>
      <a href='/main' onClick={() => {
      }}>
        <img src={logo} alt="Logo" style={{ height: '100px', cursor: 'pointer', padding: '.5em' }} />
      </a>
    </div>
  );
}
export default Header;
