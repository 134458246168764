import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";

import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'

import AppSettings from '../API/appSettings';
import { Item } from '../styles';
import { Editor } from 'ketcher-react';
import { Ketcher } from 'ketcher-core'
import 'ketcher-react/dist/index.css'
import '../App.css'

let structServiceProvider: any;
var _ketcher: Ketcher;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const errorHandler = (message: string) => { console.log(message) };
type IProps = { //session: string,
  //spectrumPreview?: ISpectrumPreviewModel
};

const ReactionEditorPage: React.FC<IProps> = (props) => {

  const buttonRef = useRef<HTMLButtonElement>(null);

  let history = useHistory();
  const [_molfile, setmolfile] = React.useState<string>('');



  const { StandaloneStructServiceProvider } = require('ketcher-standalone')
  structServiceProvider = new StandaloneStructServiceProvider();

  const [isDone, setIsDone] = useState<boolean>(false);

  function goBack() {
    console.log('goBack triggered');
    _ketcher.editor.unsubscribe('change', subscriber);
    _ketcher.eventBus.removeAllListeners();
    (window as any).ketcher = null;
    setIsDone(true);
  }


  useEffect(() => {
    if (isDone) {
      console.log('go to /main');
      history.push('/main')
    }
  }, [isDone]);


  function isMoleculeEmpty(mol: string) {
    if (!mol) return true;
    return mol.includes('V30 COUNTS 0 0 0 0 0');
  }


  const subscriber = (e: any) => {
    try {

      if (buttonRef.current)
        buttonRef.current.disabled = !_ketcher.containsReaction();

      if (_ketcher.containsReaction()) {
        //    if (buttonRef.current)
        //        buttonRef.current.disabled = isMoleculeEmpty(m);

        //_ketcher.getRxn('v3000').then(async (m) => {
        //setmolfile(m);
        //if (buttonRef.current)
        //    buttonRef.current.disabled = isMoleculeEmpty(m);

        //let blob = await _ketcher.generateImage(m);
        //  buttonRef.current.disabled = false; 
        //setNumberOfFragments(isMoleculeEmpty(m) ? 0 : 1);
        //disabled={isMoleculeEmpty(_molfile.get(props['session']) as string)} 
        //});
      }
      else {
        console.log('it is not reaction yet');
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }


  return (
    <Grid container md={12} spacing={0} className='main-frame' style={{ display: 'flex', height: '100vh', flexDirection: 'column', alignItems: 'center' }}>
      <Grid style={{ width: "1440px", backgroundColor: '#4a4e69', marginTop: '50px', height: '100%', minHeight: '800px', marginBottom: '50px', borderRadius: '40px' }}>
        <div style={{textAlign: 'center', position: 'absolute',  top:'70px', width: '1440px'}}>
          <Typography style={{ color: '#ff792d', fontSize: '40px', fontFamily: 'Roboto, Helvetica' }}>
            Please draw reaction, not compound
          </Typography>
          </div>
        <Item style={{ width: '988px', height: '580px', marginLeft: 'auto', marginRight: 'auto', marginTop: '100px' }}>
          <Editor
            staticResourcesUrl={process.env.PUBLIC_URL}
            structServiceProvider={structServiceProvider}
            errorHandler={errorHandler}
            onInit={(ketcher: any) => {
              _ketcher = ketcher;
              let molecule = localStorage.getItem(AppSettings.LOCAL_REACT_MOL_KEY);
              if (!molecule) {
                molecule = 'o>>';
              }

              if (molecule) _ketcher.setMolecule(molecule);
              (window as any).ketcher = ketcher;
              _ketcher.editor.subscribe('change', subscriber);
              if (buttonRef.current)
                buttonRef.current.disabled = true;
            }}
          />
        </Item>
        <Grid container xs={12} style={{ marginTop: '20px' }}>
          <Grid xs={6} style={{ textAlign: 'center', marginLeft: '305px' }}>
            <Button variant="contained"
              ref={buttonRef}
              onClick={async () => {
                if (_ketcher.containsReaction()) {
                  let reaction = await _ketcher.getRxn('v3000');
                  console.log('reaction', reaction);
                  localStorage.setItem(AppSettings.LOCAL_REACT_MOL_KEY, (reaction as string));
                  console.log('save mol', AppSettings.LOCAL_REACT_MOL_KEY);
                  const data = await _ketcher.getRxn();
                  const svgBlob = _ketcher.generateImage(data, {
                    outputFormat: 'svg',
                  });
                  const svg = await (await svgBlob).text();
                  localStorage.setItem(AppSettings.LOCAL_REACT_SVG_KEY, svg);
                  console.log('save svg: ' + AppSettings.LOCAL_REACT_SVG_KEY, svg);
                  goBack();
                }
              }}>Ok</Button>
          </Grid>
          <Grid xs={6} style={{ textAlign: 'center', marginLeft: '215px' }}>
            <Button variant="outlined" onClick={() => { goBack() }}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ReactionEditorPage;