import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';

type IDialogProps = {
    isDialogOpened: boolean,
    errorMessage?: string,
    warningMessage?: string,
    onClose: () => void
};

export default function ErrorDialog(props: IDialogProps) {
    return (<Dialog
        open={props.isDialogOpened}
        onClose={() => { props.onClose() }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
            {props.errorMessage ? "Error" : "Warning"}
        </DialogTitle>
        <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
            <DialogContentText id="alert-dialog-description">
                {props.errorMessage}
                {props.warningMessage}
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='outlined' style={{margin:'1em'}} onClick={() => {
                props.onClose()
            }} autoFocus>
                Ok
            </Button>
        </DialogActions>
    </Dialog>);
}