import { createTheme, Theme } from "@mui/material/styles";

const theme: Theme = createTheme({
    typography: {
      fontFamily: "Geologica, Helvetica",
    },
    
    spacing: 4,
    palette: {
      primary: {
        main: '#4a4e69',
      },
      secondary: {
        main: '#337070',
      },
    },
  
  

    components: {
      
      // MuiTooltip: {
      //   styleOverrides: {
      //       tooltip: {
      //         fontSize: "1.5em",
      //         color: "black",
      //         backgroundColor: "aliceblue",
      //         border: "1px solid #E6E8ED",
      //         borderRadius: "6px",
      //     }
      //   }
      // },

      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "Geologica, Helvetica;",
          },
          link: {
          },
          body: {
            fontSize: "3rem",
            color: '#4a4e69',

          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            
            //fontSize: '.9rem',
            ...( ownerState.variant === 'contained' && {
            '&:hover': {
              backgroundColor: '#0000005e',
            } } ) ,
            fontWeight: '700',
            fontSize:'20px',
            backdropFilter: 'blur(309px) brightness()',
            backgroundColor: '#ffffff5e',
            borderRadius: '10px',
            width: '308px',
            height: '51px',
            position: 'relative',
            boxShadow: 'inset 0 1px 40px #91919133, inset 0 4px 18px #6c6c6c4c, inset 0 98px 100px -48px #8a8a8a4c, 0 4px 210px #ffffff9e',


            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                color: '#fff',
              }),
          }),
      },
    },
  }});

  export default theme;