import React from 'react';

import AppSettings from '../API/appSettings';
import { Link } from "react-router-dom";

type IProps = { svg?: string, rxn?: string, requestParams?: any };


const ReactionView: React.FC<IProps> = (props) => {

  const emptyContainer = () => {
    return (<span></span>)
  }

  const svg = props.svg ? props.svg : localStorage.getItem(AppSettings.LOCAL_REACT_SVG_KEY) as string;
  const svgString = encodeURIComponent(svg);
  const dataUri = `url("data:image/svg+xml,${svgString}")`;

  const MW = (<div className={'mol-container'}
    style={{ height: '344px', width: 'auto', alignItems: 'center', background: dataUri }}>
    {emptyContainer()}
  </div>);


  const DF = () => {
    return (<div style={{ width: '1045px', height: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
      <div style={{ textAlign: "center" }}>
        {MW}
      </div>
    </div>);
  }


  React.useEffect(() => {
    let moleculeContainers: any = Array.from(document.getElementsByClassName('mol-container'));
    moleculeContainers.map((moleculeContainer: any) => {
      moleculeContainer.style.backgroundSize = 'contain';
      moleculeContainer.style.backgroundRepeat = 'no-repeat';
      moleculeContainer.style.backgroundPositionY = 'center';
      moleculeContainer.style.margin = '20px';
    });
  }, []);


  const navigateExternal = (target: string, options: any) => {
      if (props.rxn)
        localStorage.setItem("exact-search-rxn", props.rxn);
      if (props.svg)
       localStorage.setItem("exact-search-svg", props.svg);
      if (props.requestParams)
       localStorage.setItem("exact-search-params", JSON.stringify(props.requestParams));

    window.open(target, "_blank", "noreferrer");
  }

  return (<>
    {props.rxn ?
      (<a style={{cursor: 'pointer' }} onClick={()=>navigateExternal('/exact-search', {rxn: props.rxn, svg: props.svg, requestParams: props.requestParams })}>
        {(DF())}
      </a>) : <>{DF()}</>
    }
  </>
  );
}
export default ReactionView;      