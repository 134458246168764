import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A8027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  paddingLeft: '0',
  paddingRight: '0',
  borderColor: 'red',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A8027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  boxShadow: 'none',
  borderColor: 'green',
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
  noPadding: {
    padding: 0
  },
}));
export {Item, Item2, Item3}
