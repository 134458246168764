import React from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button'
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Pagination from '@mui/material/Pagination';

import '../App.css';
import Doi from '../components/Doi';
import Header from '../components/Header';
import { getReaction } from '../services/Session';
import ISearchModel from '../schemas/ISearchModel';
import { fetchPost } from '../services/GettingData';
import ReactionView from '../components/ReactionView';
import IReactionModel from '../schemas/IReactionModel';
import ProgressControl from '../components/ProgressControl';
import IReactionPartModel from '../schemas/IReactionPartModel'; 
import IReactionSearchResponse from '../schemas/IReactionSearchResponse';

type IExactSearchProps = { searchModel?: ISearchModel };

const SubSearchPage: React.FC<IExactSearchProps> = (props) => {

  let history = useHistory();

  const [page, setPage] = React.useState<number>(1);
  const [searchModel] = React.useState<ISearchModel>({ reaction_params: ((props as any).location.state ? (props as any).location.state.requestParams : {}), 
                                                       rxn: getReaction(),
                                                       reaction_compounds: [],
                                                       products: [] });
  //rxn: getReaction() });

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [searchReactionResponse, setSearchReactionResponse] = React.useState<IReactionSearchResponse>({ items: [], total: 0, page: 1, size: 10 });


  React.useEffect(() => {
    get();
  }, [page]);


  const pageChangeHandle = async (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  }


  const DR = (reaction: IReactionModel) => {
    return (<Grid container style={{
      textAlign: 'center',
      fontSize: '16px',
      marginTop: '15px'
    }} key={reaction.id}>
      <Grid xs={12} style={{ width: '100%' }}>
        <ReactionView svg={reaction.svg} rxn={reaction.reaction} requestParams={searchModel.reaction_params} />
      </Grid>
      <Grid xs={12} style={{
        width: '100%',
        fontSize: '24px',
        fontWeight: '600',
        fontFamily: 'Roboto, Helvetica',
        textAlign: 'left',
        marginTop: '0px',
        paddingLeft: '200px'
      }}>
        {Doi.DoiShort(reaction.urn)}, yield: {reaction.products[0].yield_percent}%
      </Grid>
    </Grid>
    );
  }


  const get = async () => {
    setLoading(true);
    const response = await fetchPost('/reaction/rsub?page=' + page + '&size=10', JSON.stringify(searchModel));
    if (response.ok) {
      const exactSearchResponse = await response.json();
      setSearchReactionResponse(exactSearchResponse);
    }
    setLoading(false);
  }

  // const get = async () => {
  //   setLoading(true);
  //   const response = await fetchPost('/reaction/part-search?page=' + page + '&size=10', JSON.stringify(reactionPartModel));
  //   if (response.ok) {
  //     const exactSearchResponse = await response.json();
  //     setSearchReactionResponse(exactSearchResponse);
  //   }
  //   setLoading(false);
  // }


  return (
    <Grid container md={12} spacing={0} className='main-frame' style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', color: '#4a4e69' }}>
      <Grid style={{ width: "1440px" }}>
        <Grid>
          <Header />
        </Grid>
        <Grid xs={12}>
          <div style={{ marginLeft: '188px' }}>
            <Typography color="primary" fontSize={'96px'}>
              REACTIONS</Typography></div>
          <div style={{ marginLeft: '375px', marginTop: '-50px' }}>
            <Typography color="primary" fontSize={'96px'}>
              <span style={{ color: '#ff792d' }}>SUBSTRUCTURE</span></Typography></div>
          <div style={{ marginLeft: '550px', marginTop: '-50px' }}><Typography color="primary" fontSize={'96px'}>
            SEARCH</Typography></div>
        </Grid>

        {isLoading ? <ProgressControl /> :
          <>
            <Grid>
              <div style={{ paddingLeft: '200px', marginTop: '100px' }}>
                <Typography fontSize={'40px'} fontWeight={700}> Query reaction:</Typography></div>
              <ReactionView />
            </Grid>

            <Grid xs={12} style={{ marginBottom: '100px', textAlign: 'center' }}>
              <Button variant="contained"
                style={{ color: '#585c75' }}
                onClick={() => {
                  history.push({
                    pathname: '/reaction-filters',
                    state: { requestParams: searchModel.reaction_params, 
                             backToPage: '/sub-search' }
                  })
                }}>SET REACTION FILTERS</Button>
            </Grid>

            <Grid>
              <div style={{ paddingLeft: '200px', marginTop: '100px' }}>
                <Typography fontSize={'40px'} fontWeight={700}> Found {searchReactionResponse.total} reactions:</Typography></div>
              {searchReactionResponse?.items &&
                searchReactionResponse.items.map((reaction: IReactionModel, index: number) => (
                  (DR(reaction))))
              }
            </Grid>

            <Grid container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ width: '100%', marginTop: '80px' }}>
              {searchReactionResponse && searchReactionResponse?.total > 0 &&
                <Pagination count={Math.ceil(searchReactionResponse?.total / searchReactionResponse?.size)}
                  page={searchReactionResponse?.page} onChange={pageChangeHandle} />}
            </Grid>
          </>}
      </Grid>
      <Grid style={{ minHeight: '80px' }}></Grid>
    </Grid>

  );
}

export default SubSearchPage;